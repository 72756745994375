import LoginLogo from './LoginLogo';
import FlowStep from "../Interaction/FlowStep";
import ThingsFlow from "../Interaction/ThingsFlow";
import { useState } from "react";
import { languageCode } from "../../data/constants";
import { sendSignInLinkToEmail } from 'firebase/auth';
import { inter } from "../../utils/internationalization";
import { auth, actionCodeSettings } from "../../integrations/firebase";

const sendEmailLink = async ({email, setIsEmailLinkSent}) => {
  if(!email) {
    return;
  }

  sendSignInLinkToEmail(auth, email, actionCodeSettings)
  // Link successfully sent.
  .then(() => {
    // Save email locally to not request again if User opens link on same device.
    window.localStorage.setItem('userEmailForLogin', email);
    // Let User know in the page.
    setIsEmailLinkSent(true);
  })
  .catch((error) => {
    /** @TODO Setup Telegram alerts */
    console.log({ error });
  });
};

const Login = () => {
  const [email, setEmail] = useState('');
  const [isEmailLinkSent, setIsEmailLinkSent] = useState(false);

  if(isEmailLinkSent) {
    return (
      <>
        <LoginLogo />

        <div className="padding-top-bottom-xl flex column center gap-xs">
          <div className="text size-lg center">{texts.weveSentALoginLinkTo}</div>
          <div className="text size-lg center"><strong>{email}</strong></div>
          <div className="text size-md center">Please check your email and click the link to continue.</div>
        </div>
      </>
    );
  }

  return (
    <>
      <LoginLogo />

      <ThingsFlow
        finishButtonText={texts.finishButtonText}
        onFinish={() => sendEmailLink({email, setIsEmailLinkSent})}
      >
        <FlowStep>
          <div className='flex column center gap-xxxs'>
            <div className="text size-lg">{texts.welcomeTo}</div>
            <div className="text size-xl">BRAIN FLOW</div>
            <div className="text size-md">{texts.byFlowKaching}</div>
          </div>

          <div className="flex column center padding-top-bottom-xl gap-sm">
            <div className="text size-sm">{texts.pleaseEnterYourEmail}</div>
            <input className="padding-xs text size-lg" placeholder="Email" type="text" onChange={({target}) => setEmail(target.value)} />
          </div>
        </FlowStep>
      </ThingsFlow>
    </>
  );
};

const texts = inter({
  weveSentALoginLinkTo: {
    [languageCode.en]: 'We sent a login link to',
    [languageCode.es]: 'Enviamos un enlace de login a',
    [languageCode.fr]: 'On a envoyé un lien de login à',
  },
  pleaseCheckYourEmailAndClickTheLinkToContinue: {
    [languageCode.en]: 'Please check your email and click the link to continue.',
    [languageCode.es]: 'Por favor revisa tu email y haz click en el enlace para hacer login.',
    [languageCode.fr]: 'Veuillez vérifier votre email et cliquer sur le lien pour continuer.',
  },
  finishButtonText: {
    [languageCode.en]: 'Email me a login link',
    [languageCode.es]: 'Envíame el enlace de login por email',
    [languageCode.fr]: 'Envoyez-moi un lien de login par email',
  },
  welcomeTo: {
    [languageCode.en]: 'Welcome to',
    [languageCode.es]: 'Bienvenido a',
    [languageCode.fr]: 'Bienvenue à',
  },
  byFlowKaching: {
    [languageCode.en]: 'by Flow Kaching',
    [languageCode.es]: 'por Flow Kaching',
    [languageCode.fr]: 'par Flow Kaching',
  },
  pleaseEnterYourEmail: {
    [languageCode.en]: 'Please enter your email to log in',
    [languageCode.es]: 'Por favor, introduce tu email para iniciar sesión',
    [languageCode.fr]: 'Veuillez entrer votre email pour vous connecter',
  },
});

export default Login;