const Web = ({ width, height, stroke }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M21 14.5C20.5 14.376 19.6851 14.3714 19 14.376C18.7709 14.3775 18.9094 14.3678 18.6 14.376C17.7926 14.4012 17.0016 14.7368 17 15.6875C16.9982 16.7004 18 17 19 17C20 17 21 17.2312 21 18.3125C21 19.1251 20.1925 19.4812 19.1861 19.5991C18.3861 19.5991 18 19.625 17 19.5M19 20V21M19 13V14M3 12H14M3 12C3 16.9706 7.02944 21 12 21M3 12C3 7.02944 7.02944 3 12 3M12 21C12.338 21 12.6717 20.9814 13 20.9451M12 21C4.75561 13.08 8.98151 5.7 12 3M12 3C15.9187 3 19.2524 5.50442 20.4879 9M12 3C13.3399 4.19853 14.9177 6.31924 15.6246 9"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>{" "}
      </g>
    </svg>
  );
};

export default Web;
