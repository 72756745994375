import Icon from "../Icon/Icon";
import { dimensionsVsSizeMap } from "../../data/constants";

const IconAndDetailNonEditable = ({icon, value, overrideValue, size = 'md'}) => {
  if(!value) {
    return null;
  };

  return (
    <div className='flex gap-xxs'>
      <div>
        <Icon name={icon} dimensions={dimensionsVsSizeMap[size]} stroke='var(--palette-action)'/>
      </div>

      <div className={`text size-${size}`} style={{overflow: 'hidden', maxHeight: '3em', textOverflow: 'ellipsis'}}>{overrideValue ? overrideValue : value}</div>
    </div>
  )
}

export default IconAndDetailNonEditable;