import { useNavigate } from "react-router-dom";
import IconAndDetailNonEditable from "../Global/IconAndDetailNonEditable";
import { icon } from "../../data/constants";
import { globalTexts } from "../../utils/internationalization";

const ProjectItem = ({project}) => {
  const navigate = useNavigate();

  return (
    <div className='rounded-xs back-grayscale-8 pointer apadding-xxs' style={{width: '128px', xOverflow: 'hidden'}} onClick={() => navigate(`/project/${project.partnerId}/${project.clientId}/${project.id}`)}>
      <div className='text size-lg bold color-grayscale-0 padding-top-xs padding-left-right-xs'>{project.name}</div>

      {project.imageUrl && <img src={`${process.env.REACT_APP_IMAGE_URL}/${project.imageUrl}`} className='padding-top-xs' style={{width: '128px', height: '64px'}} />}

      <div className='flex column gap-xs back-grayscale-4 padding-xs rounded-xs' style={{position: 'relative', top: 8, left: 8,}}>
        <IconAndDetailNonEditable value={globalTexts[project.status]} icon={icon.tree} size='sm' />
        <IconAndDetailNonEditable value={project.address} icon={icon.address} size='sm' />
        <IconAndDetailNonEditable value={project.description} icon={icon.message} size='sm' />
        <IconAndDetailNonEditable value={project.startDate} icon={icon.calendar} size='sm' />
        <IconAndDetailNonEditable value={project.endDate} icon={icon.calendarCheck} size='sm' />
        <IconAndDetailNonEditable value={project.notes} icon={icon.cocktail} size='sm' />
      </div>
    </div>
  );
};

export default ProjectItem;
