import Icon from "../Icon/Icon";
import { dimensionsVsSizeMap } from "../../data/constants";

const IconAndPropertyEditable = ({propertyName, value, overrideValue, icon, onClick, entityMetadata, size = 'md'}) => {
  const propertyMetadata = entityMetadata && entityMetadata[propertyName];
  const iconToShow = propertyMetadata && propertyMetadata.icon || icon;
  const labelText = propertyMetadata && propertyMetadata.labelText || propertyName;

  return (
    <>
      <div className='flex align-center gap-xs pointer hover-grayscale-2' onClick={onClick}>
        <div>
          {iconToShow && <Icon name={iconToShow} dimensions={dimensionsVsSizeMap[size] * 2.3} stroke='var(--palette-action)' />}
        </div>

        <div className='flex column'>
          <div className={`text size-${size}`}>{labelText}</div>
          <div className={`text bold size-${size}`}>{overrideValue ? overrideValue : value}&nbsp;</div>
        </div>
      </div>
    </>
  );
};

export default IconAndPropertyEditable;