import { useEffect, useRef } from "react";

// Render element that takes full width and height of screen, and scroll to its top after render
const FullScreenNonModal = ({children, className, hidden}) => {
  const ref = useRef(null);
  const scrollRef = useRef(null);

  useEffect(() => {
    // Scroll to top of element after render
    if(ref.current && !hidden) {
      window.scrollTo({top: ref.current.offsetTop, behavior: 'smooth'});
    }
  }, [hidden]);

  // if(hidden) {
  //   return null
  // };

  return (
    // Wrapper div is important --- it allows child to be 100vw even if this FullScreenNonModal renders inside a display: flex; element
    <div className={className} ref={ref}>
      <div ref={scrollRef} style={{width: '100vw', height: '100vh', overflowY: 'scroll', position: 'relative', left: 0}}>
        {children}
      </div>
    </div>
  )
}

export default FullScreenNonModal;