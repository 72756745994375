import QrCode from "./QrCode";
import AppBar from "../AppBar/AppBar";
import geoIp from "../../integrations/geoip";
import dataLayer from "../../data/dataLayer";
import templates from "../../data/templates";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const test = async () => {
  console.log({testIp: await geoIp.getInfoFromIp({})});
};


const Referral = () => {
  const { partnerId, referralId } = useParams();
  const [referral, setReferral] = useState({...templates.referral, partnerId});

  useEffect(() => {
    if(referralId || localStorage.getItem('referralId')) {
      const idForSearch = referralId || localStorage.getItem('referralId');
      dataLayer.referral.onGet({partnerId, referralId: idForSearch, callback: ({document}) => setReferral(document)});

      return;
    };

    dataLayer.referral.onUpdate({referral, callback: ({document}) => localStorage.setItem('referralId', document.id)});
  }, []);

  return (
    <>
      <AppBar />

      <div className='text center size-xl padding-md'>{referral.code}</div>
      <div className='flex center padding-md'>
        <QrCode codeValue={referral.code} />
      </div>
      <div className='text'>Instructions</div>
      <div className='text'>QR</div>
      <div className='text'>Message to share vía whatsapp, email, etc. Contains the URL and code to redeem the incentive</div>
      <div className='text'>URL</div>
      <div className='text'>Copy URL button</div>
      <div className='text' onClick={() => {localStorage.removeItem('referralId'); window.location.reload()}}>Make a new referral button</div>
    </>
  )
};

export default Referral;