import React from 'react';
import bgPurpleRoughShimmer1 from '../../assets/img/bgPurpleRoughShimmer1.jpg';

const Separator = () => {
  return (
    <div style={{height: '4px', overflow: 'hidden'}}>
      <img src={bgPurpleRoughShimmer1} alt='' />
    </div>
  );
};

const ThingList = ({children}) => {
  // Return children with Separator between each of them
  return React.Children.map(children, (child, index) => (
    <>
      {child}
      {index < React.Children.count(children) - 1 && <Separator />}
    </>
  ));
};

export default ThingList;