import Icon from "../Icon/Icon";
import LayoutStack from "./LayoutStack";
import placeholderLogo from '../../assets/img/placeholderLogo1.png';
import { icon } from "../../data/constants";
import { inter } from "../../utils/internationalization";

const PortalFooter = () => {
  return (
    <div className='footer back-grayscale-9'>
      <LayoutStack rows={aboveTheLine} />

      <div className='line'></div>

      <LayoutStack rows={belowTheLine} />
    </div>
  )
}

const texts = inter({
  company: {
    en: 'Company',
    es: 'Empresa',
    fr: 'Entreprise',
  },
  aboutUs: {
    en: 'About us',
    es: 'Sobre nosotros',
    fr: 'À propos de nous',
  },
  services: {
    en: 'Services',
    es: 'Servicios',
    fr: 'Services',
  },
  kitchenHoodInstallations: {
    en: 'Kitchen Hood Installations',
    es: 'Instalaciones de Hoods de Cocina',
    fr: 'Installations de Hoods de Cuisine',
  },
  contactUs: {
    en: 'Contact us',
    es: 'Contactanos',
    fr: 'Contactez-nous',
  },
  email: {
    en: 'Email',
    es: 'Email',
    fr: 'Email',
  },
  phone: {
    en: 'Phone',
    es: 'Teléfono',
    fr: 'Téléphone',
  },
  address: {
    en: 'Address',
    es: 'Dirección',
    fr: 'Adresse',
  },
  whatsapp: {
    en: 'Whatsapp',
    es: 'Whatsapp',
    fr: 'Whatsapp',
  },
  solutionsByIndustry: {
    en: 'Solutions By Industry',
    es: 'Soluciones por Industria',
    fr: 'Solutions par Industrie',
  },
  restaurants: {
    en: 'Restaurants',
    es: 'Restaurantes',
    fr: 'Restaurants',
  },
  kitchens: {
    en: 'Kitchens',
    es: 'Cocinas',
    fr: 'Cuisines',
  },
  allRightsReserved: {
    en: 'All Rights Reserved',
    es: 'Todos los derechos reservados',
    fr: 'Tous droits réservés',
  },
});

const aboveTheLine = [
  { className: 'flex center padding-top-lg', content: <img src={placeholderLogo} style={{width: '180px'}} alt='placeholder logo' />},
  { className: 'flex justify-center gap-lg padding-lg', items: [
    { items: [
      { className: 'text title uppercase', content: texts.company},
      { content: texts.aboutUs},
    ]},
    { items: [
      { className: 'text title uppercase', content: texts.services},
      { content: texts.kitchenHoodInstallations},
    ]},
    { items: [
      { className: 'text title uppercase', content: texts.contactUs},
      { className: 'flex gap-xxs', items: [
        { content: <Icon name={icon.whatsapp} stroke="#006eff" />},
        { content: texts.whatsapp},
      ]},
      { className: 'flex gap-xxs', items: [
        { content: <Icon name={icon.phone} stroke="#006eff" />},
        { content: texts.phone},
      ]},
      { className: 'flex gap-xxs', items: [
        { content: <Icon name={icon.email} stroke="#006eff" />},
        { content: texts.email},
      ]},
      { className: 'flex gap-xxs', items: [
        { content: <Icon name={icon.location} stroke="#006eff" />},
        { content: texts.address},
      ]},
    ]},
  ]},
  { className: 'flex justify-between gap-lg padding-lg', items: [
    { items: [
      { className: 'text title uppercase', content: texts.solutionsByIndustry},
      { content: texts.restaurants},
      { content: texts.kitchens},
    ]},
  ]},
]

const belowTheLine = [
  { className: 'flex justify-between wrap padding-lg', items: [
    { items: [
      { content: `A&L Commercial Hood Systems | ${texts.allRightsReserved} © 2024 | Flow Kaching`},
    ]},
    { items: [
      { className: 'flex gap-xs', items: [
        { content: <Icon name={icon.facebook} />},
        { content: <Icon name={icon.instagram} />},
        { content: <Icon name={icon.twitter} />},
        { content: <Icon name={icon.youtube} />},
        { content: '🇺🇸'},
      ]},
    ]},
  ]},
];

export default PortalFooter;