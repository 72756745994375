import { LayoutStackV2 } from "./LayoutStack";
import testimonial1 from "../../assets/img/testimonial1.jpg";
import testimonial2 from "../../assets/img/testimonial2.jpg";
import templateLogo1 from "../../assets/img/templateLogo1.png";
import templateLogo3 from "../../assets/img/templateLogo3.jpg";
import { inter } from "../../utils/internationalization";

const Testimonials = () => {
  return (
    <div className='back-grayscale-0'>
      <LayoutStackV2 rows={testimonials} />
    </div>
  )
}

const texts = inter({
  thisProductIsAmazing: {
    en: 'This product is amazing! It helped us improve our business and we are very happy with the results.',
    es: '¡Este producto es increíble! Nos ayudó a mejorar nuestro negocio y estamos muy felices con los resultados.',
    fr: 'Ce produit est incroyable! Il nous a aidé à améliorer notre entreprise et on est très heureux avec les résultats.',
  },
  IWouldTotallyRecommendIt: {
    en: 'I totally recommend it to anyone who wants to grow their business.',
    es: 'Se lo recomiendo totalmente a cualquier persona que quiera crecer su negocio.',
    fr: 'Je le recommande totalement à toute personne qui veut faire grandir son entreprise.',
  },
  thisProductChangedOurLifeAtTheRestaurant: {
    en: 'This product changed our life at the restaurant - we get more customers than before and we spend less time worrying about how or kitchen is running.',
    es: 'Este producto cambió nuestra vida en el restaurante - obtenemos más clientes que antes y nos ahorramos tiempo preocupándonos por cómo o nuestra cocina está funcionando.',
    fr: 'Ce produit a changé notre vie au restaurant - nous avons plus de clients que avant et nous économisons du temps en nous inquiétant de la façon dont notre cuisine fonctionne.',
  },
  IDontKnowWhatWeWouldDoWithoutItNowadays: {
    en: "I don't know what we would do without it nowadays.",
    es: "No sé lo que haríamos sin él hoy en día.",
    fr: "Je ne sais pas ce que nous ferions sans ça aujourd'hui.",
  },
  owner: {
    en: 'Owner',
    es: 'Propietario',
    fr: 'Propriétaire',
  },
  chef: {
    en: 'Chef',
    es: 'Chef',
    fr: 'Chef',
  },
});

const testimonials = [
  {className: 'flex wrap gap-xxl padding-xxl', items: [
    {className: 'shadow-xs', style: {maxWidth: '384px'}, items: [
      // {className: 'text center padding-xxxl back-grayscale-4', content: 'image'},
      {content: <img className='full-width' src={testimonial1} alt='testimonial' />},
      {className: 'padding-md', items: [
        {content: <img src={templateLogo1} alt='template logo' width={64} />},
        {className: 'padding-top-md'},
        {className: 'text size-sm color-dark', content: texts.thisProductIsAmazing},
        {className: 'text size-sm color-dark', content: texts.IWouldTotallyRecommendIt},
        {className: 'padding-top-bottom-xs', items: [
          {className: 'line-dark'},
        ]},
        {className: 'text title size-xl color-dark', content: 'Jimy Hendrix'},
        {className: 'text italic size-sm color-dark', content: `${texts.owner}, EXCAVATOR INC`},
      ]},
    ]},
    {className: 'shadow-xs', style: {maxWidth: '384px'}, items: [
      // {className: 'text center padding-xxxl back-grayscale-4', content: 'image'},
      {content: <img className='full-width' src={testimonial2} alt='testimonial' />},
      {className: 'padding-md', items: [
        {content: <img src={templateLogo3} alt='template logo' width={64} />},
        {className: 'padding-top-md'},
        {className: 'text size-sm color-dark', content: texts.thisProductChangedOurLifeAtTheRestaurant},
        {className: 'text size-sm color-dark', content: texts.IDontKnowWhatWeWouldDoWithoutItNowadays},
        {className: 'padding-top-bottom-xs', items: [
          {className: 'line-dark'},
        ]},
        {className: 'text title size-xl color-dark', content: 'Alexandra Smith'},
        {className: 'text italic size-sm color-dark', content: `${texts.chef} at The Last Drop Restaurant`},
      ]},
    ]},
  ]},
];

export default Testimonials;