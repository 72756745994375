import string from "../utils/string";
import { projectStatus, referralStatus } from "./constants";

const referral = {
  code: string.generateAlphaNumericCode({ digits: 6 }),
  status: referralStatus.new,
  personId: '',
  clientId: '',
  partnerId: '',
  personName: '',
  personEmail: '',
  personPhone: '',
  personTwitter: '',
  personYoutube: '',
  personLinkedin: '',
  personFacebook: '',
  personInstagram: '',
  ipAddress: '',
  leadList: [],
};

const project = {
  name: '',
  notes: '',
  endDate: '',
  address: '',
  imageUrl: '',
  clientId: '',
  partnerId: '',
  startDate: '',
  description: '',
  status: projectStatus.finished,
};

const clientPerson = {
  name: '',
  email: '',
  phone: '',
  address: '',
  instagram: '',
  twitter: '',
  youtube: '',
  linkedin: '',
  facebook: '',
  notes: '',
  clientId: '',
  partnerId: '',
};

const client = {
  name: '',
  type: '',
  address: '',
  mainPhone: '',
  mainEmail: '',
  mainWebsite: '',
  mainInstagram: '',
  notes: '',
  partnerId: '',
  preferredLanguage: '',
};

const partner = {
  name: '',
  mainEmail: '',
  mainPhone: '',
  preferredLanguage: '',
  notes: '',
};

const templates = {
  client,
  partner,
  project,
  referral,
  clientPerson,
};

export default templates;
