import React from "react";

const LayoutStack = ({ rows }) => {
  return rows.map((row, index) => (
    <div key={index} className={row.className}>
      {row.content && row.content}
      {row.items && <LayoutStack rows={row.items} />}
    </div>
  ))
}

const ElementToRender = ({element, children, className, style, onClick}) => {
  const elementsWhoCantHaveChildrenTags = ['img'];

  if(element && elementsWhoCantHaveChildrenTags.includes(element.type)) {
    return React.cloneElement(element, {className, style, onClick});
  }
  if(element) {
    return React.cloneElement(element, {children, className, style, onClick});
  }

  return <div className={className} style={style} onClick={onClick}>{children}</div>;
}

const LayoutStackV2 = ({ rows }) => {
  return rows.map((row, index) => (
    <ElementToRender key={index} element={row.element} className={row.className} style={row.style} onClick={row.onClick}>
      {row.content}
      {row.items && <LayoutStackV2 rows={row.items} />}
    </ElementToRender>
  ))
}

export { LayoutStackV2 };

export default LayoutStack;