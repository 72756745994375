const Tree = ({ width, height, stroke, fill }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M12 16.0004L12 20.9999M12 16.0004C11.1643 16.6281 10.1256 17 9 17C6.23858 17 4 14.7614 4 12C4 9.93595 5.25068 8.16402 7.03555 7.40068C7.33166 4.92182 9.4414 3 12 3C14.5586 3 16.6683 4.92182 16.9645 7.40068C18.7493 8.16402 20 9.93595 20 12C20 14.7614 17.7614 17 15 17C13.8744 17 12.8357 16.6281 12 16.0004ZM9 20.9999H15"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>{" "}
      </g>
    </svg>
  );
};

export default Tree;
