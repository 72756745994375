const Twitter = ({ width, height, stroke }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M22 5.9246C21.2645 6.25051 20.4744 6.47071 19.6438 6.57025C20.4911 6.06289 21.1412 5.25782 21.4477 4.29948C20.655 4.76984 19.7768 5.1116 18.8422 5.29481C18.0935 4.49855 17.0277 4 15.8474 4C13.582 4 11.7446 5.8374 11.7446 8.10464C11.7446 8.42526 11.7816 8.73707 11.8503 9.03832C8.43883 8.86656 5.41672 7.23263 3.39258 4.75046C3.04025 5.35823 2.83766 6.06289 2.83766 6.81335C2.83766 8.23677 3.56258 9.4937 4.66273 10.2292C3.98978 10.2072 3.35735 10.0231 2.80419 9.71567V9.76852C2.80419 11.7565 4.21792 13.4151 6.09583 13.7921C5.75055 13.8855 5.38853 13.9348 5.01506 13.9348C4.75081 13.9348 4.49273 13.9102 4.24258 13.8626C4.76491 15.4921 6.27993 16.6795 8.07593 16.7112C6.67101 17.8122 4.90144 18.4684 2.97948 18.4684C2.64829 18.4684 2.3215 18.449 2 18.4112C3.81626 19.5765 5.97252 20.2547 8.28909 20.2547C15.8378 20.2547 19.9644 14.0026 19.9644 8.58029C19.9644 8.40412 19.96 8.2262 19.9521 8.05003C20.7536 7.47045 21.4495 6.74905 21.9982 5.92724L22 5.9246Z"
          fill={stroke}
        ></path>{" "}
      </g>
    </svg>
  );
};

export default Twitter;
