import { icon } from "../../data/constants";
import { useNavigate } from "react-router-dom";
import IconAndDetailNonEditable from "../Global/IconAndDetailNonEditable";

const ClientPersonItem = ({clientPerson}) => {
  const navigate = useNavigate();

  return (
    <div className='rounded-xxs pointer' style={{width: '128px', overflow: 'hidden'}} onClick={() => navigate(`/client-person/${clientPerson.partnerId}/${clientPerson.clientId}/${clientPerson.id}`)}>
      <div className='text size-lg bold back-grayscale-4 padding-xs'>{clientPerson.name}</div>

      <div className='flex column gap-xs back-grayscale-2 padding-xs'>
        <IconAndDetailNonEditable value={clientPerson.phone} icon={icon.phone} size='sm' />
        <IconAndDetailNonEditable value={clientPerson.email} icon={icon.email} size='sm' />
        <IconAndDetailNonEditable value={clientPerson.address} icon={icon.address} size='sm' />
        <IconAndDetailNonEditable value={clientPerson.instagram} icon={icon.instagram} size='sm' />
        <IconAndDetailNonEditable value={clientPerson.twitter} icon={icon.twitter} size='sm' />
        <IconAndDetailNonEditable value={clientPerson.youtube} icon={icon.youtube} size='sm' />
        <IconAndDetailNonEditable value={clientPerson.linkedin} icon={icon.linkedin} size='sm' />
        <IconAndDetailNonEditable value={clientPerson.facebook} icon={icon.facebook} size='sm' />
        <IconAndDetailNonEditable value={clientPerson.notes} icon={icon.cocktail} size='sm' />
      </div>
    </div>
  );
};

export default ClientPersonItem;
