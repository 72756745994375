import Icon from '../Icon/Icon';
import Footer from '../Footer/Footer';
import AppBar from '../AppBar/AppBar';
import dataLayer from '../../data/dataLayer';
import templates from '../../data/templates';
import FlowStep from '../Interaction/FlowStep';
import ThingList from '../ThingList/ThingList';
import PartnerItem from '../Partner/PartnerItem';
import ThingsFlow from '../Interaction/ThingsFlow';
import entityMetadata from '../../entities/entityMetadata';
import ThumbEditProperty from '../Input/ThumbEditProperty';
import { useState, useEffect } from 'react';
import { icon } from '../../data/constants';
import { inter } from '../../utils/internationalization';

const editProperty = ({partner, setPartner, setEditingPropertyName}) => ({propertyName}) => {
  setPartner(partner);
  setEditingPropertyName(propertyName);
};

const saveProperty = ({partner, setEditingPropertyName}) => {
  dataLayer.partner.update({partner});
  setEditingPropertyName(null);
};

const BrainFlow = () => {
  const [partners, setPartners] = useState([]);
  const [partner, setPartner] = useState(templates.partner);
  const [isCreatingPartner, setIsCreatingPartner] = useState(false);
  const [editingPropertyName, setEditingPropertyName] = useState(null);

  useEffect(() => {
    dataLayer.partner.onListChange({callback: ({documents}) => setPartners(documents)});
  }, []);

  return (
    <>
      <AppBar />

      <div className='padding-sm'>
      </div>

      <div className='flex column'>
        <div className='flex center justify-between padding-xxs back-grayscale-3 pill'>
          <input className='seamless padding-left-right-sm text size-lg' type='text' placeholder={texts.filter} />
          <div className='flex center circle back-action' style={{width: '32px', height: '32px'}}><Icon name={icon.find} dimensions='22' /></div>
        </div>
      </div>

      <div className='padding-top-bottom-sm'>

        <ThingList>
          {partners.map((partner, index) => (
            <PartnerItem partner={partner} key={index} />
          ))}
        </ThingList>
      </div>

      {!isCreatingPartner && (
        <div className='flex column'>
          <div className='flex align-center padding-xxs back-grayscale-3 pill' onClick={() => setIsCreatingPartner(true)}>
            <div className='flex center circle back-action' style={{width: '32px', height: '32px'}}><Icon name={icon.add} dimensions='22' /></div>
            <div className='text size-xl padding-left-right-sm'>{texts.addPartner}</div>
          </div>
        </div>
      )}

      {isCreatingPartner && (
        <ThingsFlow
          onFinish={() => dataLayer.partner.update({partner})}
          finishButtonText={texts.createPartner}
        >
          <FlowStep>
            <div className='flex column padding-xxxl'>
              <div className='text size-md'>{texts.name}</div>
              <input type='text' value={partner.name} onChange={e => setPartner({...partner, name: e.target.value})} />
            </div>
          </FlowStep>
        </ThingsFlow>
      )}

      <Footer />
    </>
  );
};

const texts = inter({
  name: {
    en: 'Name',
    es: 'Nombre',
    fr: 'Nom',
  },
  createPartner: {
    en: 'Create Partner',
    es: 'Crear Socio',
    fr: 'Créer un Partenaire',
  },
  addPartner: {
    en: 'Add Partner',
    es: 'Agregar Socio',
    fr: 'Ajouter un Partenaire',
  },
  editProperty: {
    en: 'Edit Property',
    es: 'Editar Propiedad',
    fr: 'Modifier la Propriété',
  },
  mainWebsite: {
    en: 'Main website of the business',
    es: 'Sitio web principal del negocio',
    fr: 'Site web principal de l\'entreprise',
  },
  filter: {
    en: 'Filter',
    es: 'Filtrar',
    fr: 'Filtrer',
  },
});

export default BrainFlow;