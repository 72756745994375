import React, { useState } from "react";

const ThingsFlow = ({children, finishButtonText, onFinish}) => {
  const [currentStep, setCurrentStep] = useState(0);

  // Account for React passing children as array (many children) or object (single child)
  const childrenList = Array.isArray(children) ? children : [children];
  const currentStepElement = childrenList[currentStep];
  const isLastStep = currentStep === childrenList.length - 1;

  return (
    // Pass currentStep properties to current step element
    React.cloneElement(currentStepElement, {currentStep, setCurrentStep, onFinish: isLastStep ? onFinish : null, finishButtonText})
  );
};

export default ThingsFlow;