const PortalCss = `
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');


/* Palette */
:root {
  --palette-primary: #006eff;

  --palette-action: #630ab7;
  --palette-action-hover: #54099d;
  --palette-action-active: #450783;

  --palette-action-secondary: #ff9900;
  --palette-action-secondary-hover: #e68a00;
  --palette-action-secondary-active: #cc7a00;

  --grayscale-0: #ffffff;
  --grayscale-1: #f9fafb;
  --grayscale-2: #f3f4f6;
  --grayscale-3: #e5e7eb;
  --grayscale-4: #d1d5db;
  --grayscale-5: #9ca3af;
  --grayscale-6: #4b5563;
  --grayscale-7: #374151;
  --grayscale-8: #1f2937;
  --grayscale-9: #000000;
}


/* Globals */
body {
  color: var(--grayscale-0);
  font-family: 'Poppins', sans-serif;
  background-color: var(--grayscale-8);
}


/* Shapes */
.circle { border-radius: 50%; }
.pill { border-radius: 9999px; }
.line {
  border-bottom: 1px solid var(--grayscale-0);
}
.line-dark {
  border-bottom: 1px solid var(--grayscale-8);
}


/* Typography */
.text.title {
  font-weight: 900;
  font-style: italic;
  font-family: 'Work Sans', sans-serif;
  text-shadow: 2px 2px 0 var(--grayscale-9);
}
.text.color-dark {
  color: var(--grayscale-8);
}
.text.color-dark.title {
  text-shadow: 2px 2px 0 var(--grayscale-4);
}
`;

export default PortalCss;