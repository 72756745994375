import LoginLogo from './LoginLogo';
import FlowStep from '../Interaction/FlowStep';
import ThingsFlow from '../Interaction/ThingsFlow';
import { useState } from 'react';
import { auth } from '../../integrations/firebase';
import { Navigate, useNavigate } from 'react-router-dom';
import { inter } from '../../utils/internationalization';
import { useAuthState } from 'react-firebase-hooks/auth';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';

const validateEmail = ({email}) => {
  if(!isSignInWithEmailLink(auth, window.location.href)) {
    return;
  }

  // The client SDK will parse the code from the link for you.
  signInWithEmailLink(auth, email, window.location.href)
    .then((result) => {
      // Clear email from storage.
      window.localStorage.removeItem('emailForSignIn');
    })
    .catch((error) => {
      /** @TODO Setup Telegram alerts */
      console.log({ error });
    });
};

const LoginFinishAfterClickingEmailLink = () => {
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const [email, setEmail] = useState(localStorage.getItem('userEmailForLogin'));

  if(user) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <LoginLogo />

      <ThingsFlow finishButtonText={texts.login} onFinish={() => validateEmail({email})}>
        <FlowStep>
          <div className="flex column center gap-xxxs">
            <div className="text size-md center">{texts.pleaseConfirmYourEmailToLogin}</div>
            <input className="padding-xs text size-lg" type="text" value={email} onChange={({target}) => setEmail(target.value)} />
            <div className='padding-md'>
              <div className="text-only-action padding-xs" onClick={() => navigate('/')}>{texts.tryAgain}</div>
            </div>
          </div>
        </FlowStep>
      </ThingsFlow>


    </>
  );
};

const texts = inter({
  pleaseConfirmYourEmailToLogin: {
    en: 'Please confirm your email to login',
    es: 'Por favor confirma tu email para hacer login',
    fr: 'Veuillez confirmer votre email pour faire login',
  },
  login: {
    en: 'Login',
    es: 'Login',
    fr: 'Login',
  },
  tryAgain: {
    en: 'Try again',
    es: 'Reintentar',
    fr: 'Réessayer',
  },
});

export default LoginFinishAfterClickingEmailLink;