import Icon from "../Icon/Icon";
import LayoutStack from "./LayoutStack";
import bgVid1 from '../../assets/img/bgVid1.mp4';
import { icon } from "../../data/constants";
import { inter } from "../../utils/internationalization";
import { useEffect, useRef } from "react";

const Ratings = () => {
  const videoRef = useRef(null);

  const Video = () => {
    return <video autoPlay muted loop ref={videoRef} style={{width: '100%', height: '100%', objectFit: 'cover'}}>
      <source src={bgVid1} type='video/mp4' />
    </video>
  }

  useEffect(() => {
    videoRef.current.playbackRate = 0.66;
  }, []);

  return (
    <div style={{position: 'relative', overflow: 'hidden'}}>
      <div style={{position: 'absolute', top: '-0%', left: '0%', width: '100%', height: '100%', opacity: 0.2, filter: 'blur(1px)', zIndex: -1}}>
        <Video />
      </div>

      <LayoutStack rows={ratings} />
    </div>
  )
}

const texts = inter({
  checkOutOurAmazingRatings: {
    en: 'Check out our amazing ratings!',
    es: '¡Mira nuestros ratings increíbles!',
    fr: 'Voir nos incroyables évaluations!',
  },
});

const Star = ({isFull = true}) => {
  return <Icon name={isFull ? icon.star : icon.halfstar} stroke="#ff9900" fill={"#ff9900"} />
}

const ratings = [
  {className: 'flex column center gap-md padding-lg', items: [
    {className: 'text center title size-xxl uppercase', content: texts.checkOutOurAmazingRatings},
    {className: 'padding-xs'},
    {className: 'text size-xxl', content: 'Google · 4.6'},
    { className: 'flex', items: [
      {content: <Star />},{content: <Star />},{content: <Star />},{content: <Star />},{content: <Star isFull={false} />},
    ]},
    {className: 'text size-xxl', content: 'TrustPilot · 4.7'},
    { className: 'flex', items: [
      {content: <Star />},{content: <Star />},{content: <Star />},{content: <Star />},{content: <Star isFull={false} />},
    ]},
    {className: 'text size-xxl', content: 'Facebook · 4.3'},
    { className: 'flex', items: [
      {content: <Star />},{content: <Star />},{content: <Star />},{content: <Star />},{content: <Star isFull={false} />},
    ]},
  ]}
]

export default Ratings;