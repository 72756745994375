import IconAndDetailNonEditable from '../Global/IconAndDetailNonEditable';
import { icon } from '../../data/constants';
import { useNavigate } from 'react-router-dom';

const logo1 = require('../../assets/img/templateLogo1.png');
const logo2 = require('../../assets/img/templateLogo2.png');
const logo3 = require('../../assets/img/templateLogo3.jpg');

const getNumberFromString = (string) => {
  return string.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
};

const ClientItem = ({client}) => {
  const logos = [logo1, logo2, logo3];
  const navigate = useNavigate();
  const logoSrc = client.imageUrl ? (`${process.env.REACT_APP_IMAGE_URL}/${client.imageUrl}`) : (logos[getNumberFromString(client.name) % logos.length]);

  return (
    <div className='flex padding-lg pointer' onClick={() => navigate(`/client/${client.partnerId}/${client.id}`)}>
      <div className='flex column gap-xs' style={{width: '82%'}}>
        <div className='text size-xl bold'>{client.type} {client.name}</div>
        <div className='flex gap-md'>
          <IconAndDetailNonEditable icon={icon.phone} value={client.mainPhone} />
          <IconAndDetailNonEditable icon={icon.email} value={client.mainEmail} />
        </div>
        <div className='flex'>
          <IconAndDetailNonEditable icon={icon.web} value={client.mainWebsite} />
        </div>
        <div className='flex'>
          <IconAndDetailNonEditable icon={icon.address} value={client.mainAddress} />
        </div>
      </div>

      <img src={logoSrc} style={{width: '18%', maxHeight: '128px', objectFit: 'contain'}} />
    </div>
  );
};

export default ClientItem;
