import { elementType } from "../../data/constants";

const Input = ({type = elementType.text, options, label, placeholder, value, onChange, size = 'size-lg'}) => {
  const placeholderText = placeholder ? placeholder : label;
  console.log({label, type});

  if(type === elementType.emoji) {
    return (
      <div className='flex wrap gap-xs'>
        {options.map(option => (
          <div className={`text ${size} padding-xxs pointer hover-grayscale-3 rounded-xs ${value === option.value ? 'back-grayscale-5' : ''}`} onClick={() => onChange({target: {value: option.value}})}>{option.label}</div>
        ))}
      </div>
    );
  }

  if(type === elementType.textarea) {
    return <textarea placeholder={placeholderText} className={`text ${size} padding-sm`} value={value} rows={4} onChange={onChange} />;
  }

  return <input placeholder={placeholderText} className={`text ${size} padding-sm`} type={type} value={value} onChange={onChange} />;
};

export default Input;
