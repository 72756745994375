import Input from "./Input";

const ThumbInput = ({label, tip, placeholder, value, onChange, size = 'size-lg', type, options}) => {
  return (
    <div className='flex column padding-xl gap-md'>
      <div className='flex column gap-xxxs'>
        <div className='text center size-lg color-action-secondary'>{label}</div>
        <div className='text center size-sm color-grayscale-6'>{tip}</div>
      </div>

      <Input label={label} placeholder={placeholder} size={size} type={type} options={options} value={value} onChange={onChange} />
    </div>
  );
};

export default ThumbInput;
