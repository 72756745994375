import './App.css';
import Login from './components/Login/Login';
import Client from './components/Client/Client';
import Portal from './components/Portal/Portal';
import Partner from './components/Partner/Partner';
import Project from './components/Project/Project';
import Referral from './components/Referral/Referral';
import BrainFlow from './components/BrainFlow/BrainFlow';
import ClientPerson from './components/Client/ClientPerson';
import PartnerEvents from './components/Partner/PartnerEvents';
import PartnerDetailedInfo from './components/Partner/PartnerDetailedInfo';
import LoginFinishAfterClickingEmailLink from './components/Login/LoginFinishAfterClickingEmailLink';
import { auth } from './integrations/firebase';
import { languageCode } from './data/constants';
import { useAuthState } from 'react-firebase-hooks/auth';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// initialize react router
const router = createBrowserRouter([
  {
    path: "/",
    element: <BrainFlow />
  },
    {
      path: "/client/:partnerId/:clientId",
      element: <Client />
  },
  {
    path: "/client-person/:partnerId/:clientId/:personId",
    element: <ClientPerson />
  },
  {
    path: "/project/:partnerId/:clientId/:projectId",
    element: <Project />
  },
  {
    path: "/referral/:partnerId/:referralId?",
    element: <Referral />
  },
  {
    path: "/partner/:partnerId",
    element: <Partner />
  },
  {
    path: '/partner-detailed-info/:partnerId',
    element: <PartnerDetailedInfo />
  },
  {
    path: "/events/:partnerId",
    element: <PartnerEvents />
  },
  {
    path: "/portal/:partnerId",
    element: <Portal />
  },
  {
    path: '/login-finish-after-clicking-email-link',
    element: <LoginFinishAfterClickingEmailLink />,
  },
]);

const isNobodyLoggedIn = ({user, isLoading}) => {
  return (!user && !isLoading && window.location.pathname !== '/login-finish-after-clicking-email-link');
};

if(!localStorage.getItem('languageCode')) {
  localStorage.setItem('languageCode', languageCode.fr);
}

function App() {
  const [user, isLoading] = useAuthState(auth);

  if(isNobodyLoggedIn({user, isLoading})) {
    // return <Login />
  }

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;