import React from "react";
import { languageCode } from "../../data/constants";
import { inter } from "../../utils/internationalization";
import Thumb from "./Thumb";

const texts = inter({
  next: {
    [languageCode.en]: 'Next',
    [languageCode.es]: 'Siguiente',
    [languageCode.fr]: 'Suivant',
  },
  finish: {
    [languageCode.en]: 'Finish',
    [languageCode.es]: 'Finalizar',
    [languageCode.fr]: 'Terminer',
  },
});

const FlowStep = ({children, currentStep, setCurrentStep, nextStepElementOverride = null, finishButtonText, onFinish = null}) => {

  const NextAction = () => {
    if (nextStepElementOverride) {
      return React.cloneElement(nextStepElementOverride, {onClick: () => setCurrentStep(currentStep + 1)});
    }

    if (onFinish) {
      return (
        <Thumb onClick={onFinish}>
          <div className="action">
            <div className="padding-md text center size-xl">{finishButtonText || texts.finish}</div>
          </div>
        </Thumb>
      )
    }

    return (
      <Thumb onClick={() => setCurrentStep(currentStep + 1)}>
        <div className="action">
          <div className="padding-md text center size-xl">{texts.next}</div>
        </div>
      </Thumb>
    )
  }

  return (
    <>
      {children}
      <NextAction />
    </>
  );
};

export default FlowStep;