import { db } from "../integrations/firebase";
import { addDoc, collection, deleteDoc, doc, getDoc, onSnapshot, query, setDoc } from "firebase/firestore";
import geoIp from "../integrations/geoip";

// Helpers
// Convert Firestore doc to a flat JSON object
const getDocAsJson = async ({ docRef }) => {
  const docSnap = await getDoc(docRef);

  return { ...docSnap.data(), id: docSnap.id };
};

/**
 * @TODO method technically does NOT need to return document and spend 1 Read operation. Could be optimized passing a 'return' param.
 */
const udpateDocWithTimestamps = async ({collectionPath, data, merge = true}) => {
  const currentDate = new Date();

  // active and createdAt can be overridden by data, updatedAt can not.
  const dataToPersist = {
    active: true,
    createdAt: currentDate,
    ...data,
    updatedAt: currentDate,
  };

  // If data has no id, create new document in collection.
  if(!data.id) {
    const collectionRef = collection(db, collectionPath);

    return getDocAsJson({docRef: await addDoc(collectionRef, dataToPersist)});
  }

  /** @TODO handle document not found */
  const docRef = doc(db, `${collectionPath}/${data.id}`);
  await setDoc(docRef, dataToPersist, { merge });
  return getDocAsJson({docRef});
}

const setCallbackOnQuerySnapshot = async ({query, callback}) => {
  onSnapshot(query, querySnapshot => {
    const documents = [];
    querySnapshot.forEach(doc => {
        documents.push({...doc.data(), id: doc.id});
    });

    callback({ documents });
  });
}

const setCallbackAfterGetDoc = async ({docRef, callback}) => {
  const docSnap = await getDoc(docRef);
  const document = { ...docSnap.data(), id: docSnap.id };

  callback({ document });
}

// Project
const project = {
  update: ({project}) => {
    return udpateDocWithTimestamps({collectionPath: `partners/${project.partnerId}/clients/${project.clientId}/projects`, data: project});
  },
  onGet: async ({partnerId, clientId, projectId, callback}) => {
    const docRef = doc(db, `partners/${partnerId}/clients/${clientId}/projects/${projectId}`);

    setCallbackAfterGetDoc({docRef, callback});
  },
  onListChange: async ({partnerId, clientId, callback}) => {
    const q = query(collection(db, `partners/${partnerId}/clients/${clientId}/projects`));

    setCallbackOnQuerySnapshot({query: q, callback});
  },
  delete: async ({partnerId, clientId, projectId}) => {
    const docRef = doc(db, `partners/${partnerId}/clients/${clientId}/projects/${projectId}`);
    await deleteDoc(docRef);
  },
};

// Person
const clientPerson = {
  update: ({person}) => {
    return udpateDocWithTimestamps({collectionPath: `partners/${person.partnerId}/clients/${person.clientId}/persons`, data: person});
  },
  onGet: async ({partnerId, clientId, personId, callback}) => {
    const docRef = doc(db, `partners/${partnerId}/clients/${clientId}/persons/${personId}`);

    setCallbackAfterGetDoc({docRef, callback});
  },
  onListChange: async ({partnerId, clientId, callback}) => {
    const q = query(collection(db, `partners/${partnerId}/clients/${clientId}/persons`));

    setCallbackOnQuerySnapshot({query: q, callback});
  },
  delete: async ({partnerId, clientId, personId}) => {
    const docRef = doc(db, `partners/${partnerId}/clients/${clientId}/persons/${personId}`);
    await deleteDoc(docRef);
  },
};

// Referral
const referral = {
  onUpdate: async ({referral, callback = () => {}}) => {
    // When creating a new referral, add geoIp info.
    const dataToPersist = {...referral};
    if(!referral.id) {
      const geoIpInfo = await geoIp.getInfoFromIp({});
      dataToPersist.geo = {
        lat: geoIpInfo.lat,
        lon: geoIpInfo.lon,
        ip: geoIpInfo.query,
        city: geoIpInfo.city,
        region: geoIpInfo.region,
        regionName: geoIpInfo.regionName,
        country: geoIpInfo.country,
        countryCode: geoIpInfo.countryCode,
      };
    }
    const document = await udpateDocWithTimestamps({collectionPath: `partners/${referral.partnerId}/referrals`, data: dataToPersist});

    callback({document});
  },
  onGet: async ({partnerId, referralId, callback}) => {
    const docRef = doc(db, `partners/${partnerId}/referrals/${referralId}`);

    setCallbackAfterGetDoc({docRef, callback});
  },
};

// Client
const client = {
  update: ({client}) => {
    return udpateDocWithTimestamps({collectionPath: `partners/${client.partnerId}/clients`, data: client});
  },
  onGet: async ({partnerId, clientId, callback}) => {
    const docRef = doc(db, `partners/${partnerId}/clients/${clientId}`);

    setCallbackAfterGetDoc({docRef, callback});
  },
  onListChange: async ({partnerId, callback}) => {
    const q = query(collection(db, `partners/${partnerId}/clients`));

    setCallbackOnQuerySnapshot({query: q, callback});
  },
  delete: async ({partnerId, clientId}) => {
    const docRef = doc(db, `partners/${partnerId}/clients/${clientId}`);
    await deleteDoc(docRef);
  },
};


// Partner
const partner = {
  update: ({partner}) => {
    return udpateDocWithTimestamps({collectionPath: 'partners', data: partner});
  },
  onGet: async ({partnerId, callback}) => {
    const docRef = doc(db, `partners/${partnerId}`);

    setCallbackAfterGetDoc({docRef, callback});
  },
  onListChange: async ({callback}) => {
    const q = query(collection(db, `partners`));

    setCallbackOnQuerySnapshot({query: q, callback});
  },
};


// User
const user = {
  update: ({user}) => {
    return udpateDocWithTimestamps({collectionPath: 'users', data: user});
  }
};


// DataLayer object as such
const dataLayer = {
  user,
  client,
  partner,
  project,
  referral,
  clientPerson,
};

export default dataLayer;