import Icon from "../Icon/Icon";
import AppBar from "../AppBar/AppBar";
import dataLayer from "../../data/dataLayer";
import entityMetadata from "../../entities/entityMetadata";
import ThumbEditProperty from "../Input/ThumbEditProperty";
import IconAndPropertyEditable from "../Global/IconAndPropertyEditable";
import { icon } from "../../data/constants";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { globalTexts } from "../../utils/internationalization";

const saveProject = async ({project, setEditingPropertyName}) => {
  await dataLayer.project.update({project});
  setEditingPropertyName(null);
};

const Project = () => {
  const navigate = useNavigate();
  const {partnerId, clientId, projectId} = useParams();
  const [project, setProject] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [editingPropertyName, setEditingPropertyName] = useState(null);

  useEffect(() => {
    dataLayer.project.onGet({partnerId, clientId, projectId, callback: ({document}) => setProject(document)});
  }, []);

  return (
    <>
      <AppBar backUrl={`/client/${partnerId}/${clientId}`} />

      <div className='padding-lg'>
        <div className='text size-xxl'>{project.name}</div>
        <div className='flex column gap-md padding-top-xl'>
          <IconAndPropertyEditable propertyName='name' value={project.name} onClick={() => setEditingPropertyName('name')} entityMetadata={entityMetadata.project} />
          <IconAndPropertyEditable propertyName='address' value={project.address} onClick={() => setEditingPropertyName('address')} entityMetadata={entityMetadata.project} />
          <IconAndPropertyEditable propertyName='status' value={project.status} onClick={() => setEditingPropertyName('status')} entityMetadata={entityMetadata.project} />
          <IconAndPropertyEditable propertyName='imageUrl' value={project.imageUrl} onClick={() => setEditingPropertyName('imageUrl')} entityMetadata={entityMetadata.project} />
          <IconAndPropertyEditable propertyName='startDate' value={project.startDate} onClick={() => setEditingPropertyName('startDate')} entityMetadata={entityMetadata.project} />
          <IconAndPropertyEditable propertyName='endDate' value={project.endDate} onClick={() => setEditingPropertyName('endDate')} entityMetadata={entityMetadata.project} />
          <IconAndPropertyEditable propertyName='description' value={project.description} onClick={() => setEditingPropertyName('description')} entityMetadata={entityMetadata.project} />
          <IconAndPropertyEditable propertyName='notes' value={project.notes} onClick={() => setEditingPropertyName('notes')} entityMetadata={entityMetadata.project} />
        </div>

        <div className='padding-top-xxxl'>
          <div className='padding-md flex center pointer' onClick={() => setIsDeleting(true)}><Icon name={icon.delete} stroke='var(--palette-action)' /></div>
          {isDeleting && (
            <div className='padding-md action text size-lg center' onClick={async () => {await dataLayer.project.delete({partnerId, clientId, projectId}); navigate(`/client/${partnerId}/${clientId}`)}}>{globalTexts.confirmDelete}</div>
          )}
        </div>
      </div>

      {editingPropertyName && (
        <ThumbEditProperty
          propertyName={editingPropertyName}
          entity={project}
          setEntity={setProject}
          onFinish={() => saveProject({project, setEditingPropertyName})}
          finishButtonText={globalTexts.save}
          entityMetadata={entityMetadata.project}
        />
      )}
    </>
  );
};

export default Project;
