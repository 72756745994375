import Add from "./Add";
import Web from "./Web";
import Tree from "./Tree";
import User from "./User";
import Star from "./Star";
import Find from "./Find";
import Image from "./Image";
import Email from "./Email";
import Phone from "./Phone";
import Notes from "./Notes";
import QrCode from "./QrCode";
import Delete from "./Delete";
import Twitter from "./Twitter";
import Youtube from "./Youtube";
import Message from "./Message";
import Address from "./Address";
import Whatsapp from "./Whatsapp";
import Facebook from "./Facebook";
import Telegram from "./Telegram";
import Location from "./Location";
import Halfstar from "./Halfstar";
import Linkedin from "./Linkedin";
import Cocktail from "./Cocktail";
import Calendar from "./Calendar";
import Lightning from "./Lightning";
import Instagram from "./Instagram";
import ArrowLeft from "./ArrowLeft";
import ArrowRight from "./ArrowRight";
import CalendarCheck from "./CalendarCheck";
import { icon } from "../../data/constants";

// Find more icons here:
// https://www.svgrepo.com/collection/dazzle-line-icons/
// https://www.svgrepo.com/collection/social-media-fill-icons/

const Icon = ({ name, dimensions, stroke = "#ffffff", fill = "none" }) => {
  const width = dimensions ? dimensions : "24px";
  const height = dimensions ? dimensions : "24px";

  if (name === icon.image) {
    return <Image width={width} height={height} stroke={stroke} fill={fill} />;
  }

  if (name === icon.tree) {
    return <Tree width={width} height={height} stroke={stroke} fill={fill} />;
  }

  if (name === icon.calendarCheck) {
    return <CalendarCheck width={width} height={height} stroke={stroke} />;
  }

  if (name === icon.calendar) {
    return <Calendar width={width} height={height} stroke={stroke} />;
  }

  if (name === icon.lightning) {
    return <Lightning width={width} height={height} stroke={stroke} />;
  }

  if (name === icon.qrCode) {
    return <QrCode width={width} height={height} stroke={stroke} />;
  }

  if (name === icon.address) {
    return <Address width={width} height={height} stroke={stroke} />;
  }

  if (name === icon.delete) {
    return <Delete width={width} height={height} stroke={stroke} />;
  }

  if (name === icon.notes) {
    return <Notes width={width} height={height} stroke={stroke} />;
  }

  if (name === icon.cocktail) {
    return <Cocktail width={width} height={height} stroke={stroke} />;
  }

  if (name === icon.linkedin) {
    return <Linkedin width={width} height={height} stroke={stroke} />;
  }

  if (name === icon.arrowRight) {
    return <ArrowRight width={width} height={height} stroke={stroke} />;
  }

  if (name === icon.arrowLeft) {
    return <ArrowLeft width={width} height={height} stroke={stroke} />;
  }

  if (name === icon.web) {
    return <Web width={width} height={height} stroke={stroke} />;
  }

  if (name === icon.user) {
    return <User width={width} height={height} stroke={stroke} />;
  }

  if (name === icon.message) {
    return <Message width={width} height={height} stroke={stroke} />;
  }

  if (name === icon.halfstar) {
    return (
      <Halfstar width={width} height={height} stroke={stroke} fill={fill} />
    );
  }

  if (name === icon.star) {
    return <Star width={width} height={height} stroke={stroke} fill={fill} />;
  }

  if (name === icon.location) {
    return <Location width={width} height={height} stroke={stroke} />;
  }

  if (name === icon.email) {
    return <Email width={width} height={height} stroke={stroke} />;
  }

  if (name === icon.phone) {
    return <Phone width={width} height={height} stroke={stroke} />;
  }

  if (name === icon.whatsapp) {
    return <Whatsapp width={width} height={height} stroke={stroke} />;
  }

  if (name === icon.telegram) {
    return <Telegram width={width} height={height} stroke={stroke} />;
  }

  if (name === icon.twitter) {
    return <Twitter width={width} height={height} stroke={stroke} />;
  }

  if (name === icon.youtube) {
    return <Youtube width={width} height={height} stroke={stroke} />;
  }

  if (name === icon.facebook) {
    return <Facebook width={width} height={height} stroke={stroke} />;
  }

  if (name === icon.instagram) {
    return <Instagram width={width} height={height} stroke={stroke} />;
  }

  if (name === icon.find) {
    return <Find width={width} height={height} stroke={stroke} />;
  }

  if (name === icon.add) {
    return <Add width={width} height={height} stroke={stroke} />;
  }
};

export default Icon;
