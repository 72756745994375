import { useEffect } from "react";

const Thumb = ({children, onClick}) => {
  // const [bottom, setBottom] = useState(0);
  // const [visualViewportHeight, setVisualViewportHeight] = useState(visualViewport.height);
  // const [windowInnerHeight, setWindowInnerHeight] = useState(window.innerHeight);

  useEffect(() => {
    const intervalID = setInterval(() =>  {
      // setVisualViewportHeight(visualViewport.height);
      // setWindowInnerHeight(window.innerHeight);
      // const bottom = window.innerHeight - visualViewport.height;
      // setBottom(bottom);

      const thumb = document.getElementById('thumb');
      thumb.scrollIntoView({behavior: 'smooth', block: 'end'});
    }, 2 * 1000);

    return () => clearInterval(intervalID);
  }, []);

  return (
    <div id='thumb' className="thumb" onClick={onClick} style={{bottom: 0, transition: 'bottom 0.35s ease-out', zIndex: 100}}>{children}</div>
  );
};

export default Thumb;