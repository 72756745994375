import Icon from '../Icon/Icon';
import AppBar from '../AppBar/AppBar';
import Footer from '../Footer/Footer';
import Thumb from '../Interaction/Thumb';
import templates from '../../data/templates';
import dataLayer from '../../data/dataLayer';
import ClientItem from '../Client/ClientItem';
import ThingList from '../ThingList/ThingList';
import ThumbStep from '../Interaction/ThumbStep';
import ThingsFlow from '../Interaction/ThingsFlow';
import { icon } from '../../data/constants';
import { useEffect, useState } from 'react';
import { inter } from '../../utils/internationalization';
import { useNavigate, useParams } from 'react-router-dom';
import ThumbInput from '../Input/ThumbInput';

const saveClient = async ({client, navigate}) => {
  const newClient = await dataLayer.client.update({client});
  navigate(`/client/${newClient.partnerId}/${newClient.id}`);
};

const Partner = () => {
  const navigate = useNavigate();
  const {partnerId} = useParams();
  const [partner, setPartner] = useState({});
  const [clients, setClients] = useState([]);
  const [isCreatingClient, setIsCreatingClient] = useState(false);
  const [client, setClient] = useState({...templates.client, partnerId});

  useEffect(() => {
    dataLayer.partner.onGet({partnerId, callback: ({document}) => setPartner(document)});
    dataLayer.client.onListChange({partnerId, callback: ({documents}) => setClients(documents)});
  }, []);

  return (
    <>
      <AppBar backUrl={`/`} />

      <div className='padding-lg'>
        <div className="text bold size-xxl">{partner.name}</div>

        <div className='flex gap-xxxs align-center'>
          <div className="text size-md uppercase text-only-action" onClick={() => navigate(`/partner-detailed-info/${partnerId}`)}>{texts.showPartnerDetailedInfo}</div>
          <Icon name={icon.arrowRight} dimensions="18px" stroke="var(--palette-action)" />
        </div>
      </div>


      <div className='text size-xxl padding-left-right-lg padding-top-md'>{texts.clients}</div>

      <ThingList>
        {clients.map((client, index) => (
          <ClientItem client={client} key={index} />
        ))}
      </ThingList>

      {!isCreatingClient && (
        <Thumb>
          <div className='text center size-xl action padding-md' onClick={() => setIsCreatingClient(true)}>{texts.addClient}</div>
        </Thumb>
      )}

      {isCreatingClient && (
        <ThingsFlow
          onFinish={() => saveClient({client, navigate})}
          finishButtonText={texts.createClient}
        >
          <ThumbStep>
            <ThumbInput label={texts.personOrCompanyName} tip={texts.egPaulRevereOrHopeHarborSolutionsInc} value={client.name} onChange={({target}) => setClient({...client, name: target.value})} />
          </ThumbStep>
        </ThingsFlow>
      )}

      <div className='padding-xxxl' />
      <div className='padding-xxxl' />

      <Footer />
    </>
  )
}

const texts = inter({
  clients: {
    en: 'Clients',
    es: 'Clientes',
    fr: 'Clients',
  },
  showPartnerDetailedInfo: {
    en: 'Show all details',
    es: 'Mostrar todos los detalles',
    fr: 'Afficher tous les détails',
  },
  personOrCompanyName: {
    en: 'Person or company name',
    es: 'Nombre de la persona o la empresa',
    fr: 'Nom de la personne ou de l\'entreprise',
  },
  egPaulRevereOrHopeHarborSolutionsInc: {
    en: 'e.g. Paul Revere or HopeHarbor Solutions Inc.',
    es: 'e.g. María Juliana Martínez o Soluciones Esperanza LTDA',
    fr: 'e.g. Jean Dupont ou Solutions d\'Espérance S.A.',
  },
  addClient: {
    en: 'Add client',
    es: 'Agregar cliente',
    fr: 'Ajouter client',
  },
  createClient: {
    en: 'Create client',
    es: 'Crear cliente',
    fr: 'Créer client',
  },
  gotoPartnerPortal: {
    en: 'Go to partner\'s web portal',
    es: 'Ir al portal web del socio',
    fr: 'Accéder au portail web du partenaire',
  }
});

export default Partner;
