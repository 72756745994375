import { icon, languageCode } from "../../data/constants";
import Icon from "../Icon/Icon";
import LayoutStack from "./LayoutStack";

const rotateLanguage = () => {
  const rotation = {
    en: languageCode.es,
    es: languageCode.fr,
    fr: languageCode.en,
  }

  localStorage.setItem('languageCode', rotation[localStorage.getItem('languageCode')]);
  console.log({languageCode: localStorage.getItem('languageCode')});
  window.location.reload();
}

const PortalAppBar = () => {
  return (
    <>
      <div className='fixed full-width back-grayscale-9'>
        <LayoutStack rows={appBar} />
      </div>
      <div className='padding-top-xxl'></div>
    </>
  )
}

const appBar = [
  {className: 'flex justify-between padding-xs', items: [
    {},
    {className: 'flex gap-xs', items: [
      {className: 'pointer no-line-height', content: <Icon name={icon.message} dimensions='32px' onClick={rotateLanguage} />},
      {className: 'pointer no-line-height', content: <Icon name={icon.user} dimensions='32px' />},
    ]},
  ]},
]

export default PortalAppBar;
