import AppBar from "../AppBar/AppBar";
import dataLayer from "../../data/dataLayer";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { inter } from "../../utils/internationalization";

const buildMessage = ({partnerName}) => {
  const replacements = {partnerName};

  const textList = [
    texts.stepOneIntro1,
    texts.stepOneIntro2,
    texts.stepOneCheers,
    texts.stepOneWhatWeWantToAchieve1,
    texts.stepOneWhatWeWantToAchieve2,
    texts.stepOneHowWeWillDoIt1,
    texts.stepOneHowWeWillDoIt2,
    texts.stepOneHowWeWillDoIt3,
    texts.stepOneHowWeWillDoIt4,
    texts.stepOneHowWeWillDoIt5,
    texts.stepOneHowWeWillDoIt6,
    texts.stepOneHowWeWillDoIt7,
  ];

  // Join all the texts with new lines
  const message = textList.join('\n\n');

  // Do globally replace all string macros starting with {{ and ending with }}
  return message.replace(/{{\s*(\w+)\s*}}/g, (match, p1) => {
    return replacements[p1];
  });
}

const PartnerEvents = () => {
  const { partnerId } = useParams();
  const [partner, setPartner] = useState({});

  useEffect(() => {
    if (partnerId) {
      dataLayer.partner.onGet({partnerId, callback: ({document}) => setPartner(document)});
    }
  }, [partnerId]);

  return (
    <>
      <AppBar />

      <div className='text center size-xxxl padding-lg'>{partner?.name}</div>

      <div className='flex column gap-md padding-xl'>
        <div className='text size-xl'>{texts.stepOneTitle1}</div>
        <div className='text size-lg'>{texts.stepOneTitle2}</div>
        <div className='text'>{texts.stepOneInstructions}</div>
        <textarea className='textarea' value={buildMessage({partnerName: partner.name})} cols={45} rows={35} />
      </div>
    </>
  );
};

export default PartnerEvents;

const texts = inter({
  stepOneTitle1: {
    en: 'Onboarding message',
    es: 'Mensaje de integración',
    fr: 'Message d\'intégration',
  },
  stepOneTitle2: {
    en: 'Chapter 1 of our story together - harness the power of your clients',
    es: 'Capítulo 1 de nuestra historia juntos - Atrapa el poder de tus clientes',
    fr: 'Chapitre 1 de notre histoire ensemble - Capturez le pouvoir de vos clients',
  },
  stepOneInstructions: {
    en: 'Instructions: Send this message to our partner vía whatsapp',
    es: 'Instrucciones: Envía este mensaje a nuestro socio vía whatsapp',
    fr: 'Instructions: Envoyez ce message à notre partenaire via WhatsApp',
  },
  stepOneIntro1: {
    en: '*Hello from the Flow Kaching team*',
    es: '*Saludos del equipo de Flow Kaching*',
    fr: '*Bonjour de l\'équipe Flow Kaching*',
  },
  stepOneIntro2: {
    en: "Ref. Chapter 1 📖",
    es: "Ref. Capítulo 1 📖",
    fr: "Ref. Chapitre 1 📖",
  },
  stepOneCheers: {
    en: "{{partnerName}}: first of all I want to thank you for trusting us to build the next chapter of your business growth together 💚",
    es: "{{partnerName}}: primero que todo quiero agradecerte por confiar en nosotros para construir el siguiente capítulo de tu crecimiento empresarial juntos 💚",
    fr: "{{partnerName}}: en premier lieu, je vous remercie pour votre confiance en nous pour construire le prochain chapitre de votre croissance entrepreneuriale ensemble 💚",
  },
  stepOneWhatWeWantToAchieve1: {
    en: '_*"Harness the power of your clients"*_ is perhaps the most important chapter of our story.',
    es: '_*"Atrapa el poder de tus clientes"*_ es quizás el capítulo más importante de nuestra historia.',
    fr: '_*"Capturez le pouvoir de vos clients"*_ est peut-être le chapitre le plus important de notre histoire.',
  },
  stepOneWhatWeWantToAchieve2: {
    en: '🎯 Our goal for the next few days:\nMove all the info you currently have about your clients into our Flow Kaching smart database',
    es: '🎯 Nuestra meta en los próximos días:\nPasar toda la info que tienes de tus clientes a nuestra base de datos inteligente Flow Kaching',
    fr: '🎯 Notre objectif pour les jours à venir:\nMettre toutes les infos sur vos clients dans notre base de données intelligente Flow Kaching',
  },
  stepOneHowWeWillDoIt1: {
    en: '👉 How we will do it?',
    es: '👉 Cómo vamos a hacerlo?',
    fr: '👉 Comment on le fera?',
  },
  stepOneHowWeWillDoIt2: {
    en: '1. Think about all places where you have info about your clients written down or stored 🤔',
    es: '1. Piensa en todos los lugares donde tienes info de tus clientes escrita o almacenada 🤔',
    fr: '1. Pensez à tous les endroits où vous avez des infos sur vos clients écrites ou stockées 🤔',
  },
  stepOneHowWeWillDoIt3: {
    en: 'Tip: can be any document, business card, email, old paper, invoice, etc.',
    es: 'Consejo: puede ser cualquier documento, tarjeta de presentación, email, papel viejo, factura, etc.',
    fr: 'Conseil: peut être n\'importe quel document, carte de présentation, email, vieux papier, facture, etc.',
  },
  stepOneHowWeWillDoIt4: {
    en: '2. Send us all info about your clients in the way that is easiest for you! ➡️',
    es: '2. Envíanos toda la info sobre tus clientes de la manera más fácil para ti! ➡️',
    fr: '2. Envoyez-nous toutes les infos sur vos clients de la manière qui vous convient le mieux! ➡️',
  },
  stepOneHowWeWillDoIt5: {
    en: 'Tip: don\'t spend any time formatting or cleaning up the info. We will do it for you!',
    es: 'Consejo: no te gastes tiempo formateando o limpiando la info. Nosotros nos encargamos de eso!',
    fr: 'Conseil: ne vous gâchez pas de temps à formater ou nettoyer les infos. On s\'occupe de tout!',
  },
  stepOneHowWeWillDoIt6: {
    en: '🎉🎊🏆 THAT\'S IT! We will process all info and let you know when your smart database is ready!',
    es: '🎉🎊🏆 ¡LISTO! Nosotros procesamos toda la info y te avisamos cuando tu base de datos inteligente esté lista!',
    fr: '🎉🎊🏆 C\'EST FINI! On traitera toutes les infos et on vous informera quand votre base de données intelligente sera prête!',
  },
  stepOneHowWeWillDoIt7: {
    en: 'Thanks for your attention. Feel free to send me your clients info by any method you want, whatsapp or email or whater you prefer',
    es: 'Gracias por la atención. No dudes en enviarme la info de tus clientes por el método que prefieras, whatsapp o email o lo que prefieras',
    fr: 'Merci pour votre attention. N\'hésitez pas à me envoyer les infos de vos clients par la méthode qui vous convient, WhatsApp ou email ou ce que vous préférez',
  },
});
