const getInfoFromIp = async ({ipAddress = ''}) => {
  const response = await fetch(`http://ip-api.com/json/${ipAddress}`);
  const data = await response.json();

  return data;
};

const geoIp = {
  getInfoFromIp
};

export default geoIp;
