import { elementType, emojiAndDescription, icon, projectStatus } from "../data/constants";
import { globalTexts } from "../utils/internationalization";

const commonMetadata = {
  name: {
    labelText: globalTexts.name,
    icon: icon.user,
  },
  email: {
    labelText: globalTexts.email,
    elementType: 'email',
    placeholderText: 'abcdefg007@gmail.com',
    icon: icon.email,
  },
  mainEmail: {
    labelText: globalTexts.mainEmail,
    elementType: elementType.email,
    placeholderText: 'abcdefg007@gmail.com',
    icon: icon.email,
  },
  phone: {
    labelText: globalTexts.phone,
    elementType: elementType.tel,
    placeholderText: '+12003004567',
    icon: icon.phone,
    // Leave only numbers and plus sign
    formatterMethod: value => value.replace(/[^0-9+]/g, ''),
  },
  mainPhone: {
    labelText: globalTexts.mainPhone,
    elementType: elementType.tel,
    tipText: globalTexts.weWillSendMessagesToThisWhatsapp,
    placeholderText: '+12003004567',
    icon: icon.phone,
    // Leave only numbers and plus sign
    formatterMethod: value => value.replace(/[^0-9+]/g, ''),
  },
  address: {
    labelText: globalTexts.address,
    icon: icon.location,
  },
  imageUrl: {
    labelText: globalTexts.imageUrl,
    icon: icon.image,
  },
  instagram: {
    labelText: globalTexts.instagram,
    // Remove '@' from start of string and trim it
    formatterMethod: value => value.startsWith('@') ? value.slice(1).trim() : value.trim(),
    icon: icon.instagram,
  },
  mainInstagram: {
    labelText: globalTexts.mainInstagram,
    // Remove '@' from start of string and trim it
    formatterMethod: value => value.startsWith('@') ? value.slice(1).trim() : value.trim(),
    icon: icon.instagram,
  },
  twitter: {
    labelText: globalTexts.twitter,
    icon: icon.twitter,
  },
  youtube: {
    labelText: globalTexts.youtube,
    icon: icon.youtube,
  },
  linkedin: {
    labelText: globalTexts.linkedin,
    icon: icon.linkedin,
  },
  facebook: {
    labelText: globalTexts.facebook,
    icon: icon.facebook,
  },
  mainAddress: {
    labelText: globalTexts.mainAddress,
    icon: icon.location,
  },
  mainWebsite: {
    labelText: globalTexts.mainWebsite,
    // Remove protocol in upper or lower case from string and any trailing slashes
    formatterMethod: value => value.replace(/^https?:\/\//i, '').replace(/\/$/, ''),
    icon: icon.web,
  },
  notes: {
    labelText: globalTexts.notes,
    icon: icon.notes,
  },
  startDate: {
    labelText: globalTexts.startDate,
    elementType: elementType.date,
    icon: icon.calendar,
  },
  endDate: {
    labelText: globalTexts.endDate,
    elementType: elementType.date,
    icon: icon.calendarCheck,
  },
  description: {
    labelText: globalTexts.description,
    elementType: elementType.textarea,
    icon: icon.message,
  },
  status: {
    labelText: globalTexts.status,
    elementType: elementType.select,
    icon: icon.tree,
    options: [
      {value: projectStatus.onHold, label: globalTexts.onHold},
      {value: projectStatus.finished, label: globalTexts.finished},
      {value: projectStatus.cancelled, label: globalTexts.cancelled},
      {value: projectStatus.exploring, label: globalTexts.exploring},
      {value: projectStatus.inProgress, label: globalTexts.inProgress},
      {value: projectStatus.negotiating, label: globalTexts.negotiating},
      {value: projectStatus.preparingToStart, label: globalTexts.preparingToStart},
    ],
  },
};


// Project
const project = {
  name: {
    ...commonMetadata.name,
    tipText: globalTexts.projectNameOrPlaceWhereJobWasDone,
  },
  notes: commonMetadata.notes,
  address: commonMetadata.address,
  endDate: commonMetadata.endDate,
  imageUrl: commonMetadata.imageUrl,
  startDate: commonMetadata.startDate,
  description: {
    ...commonMetadata.description,
    tipText: globalTexts.whatTypeOfWorkWasDoneDuringThisProject,
  },
  status: commonMetadata.status,
};


// Client Person
const clientPerson = {
  name: commonMetadata.name,
  email: commonMetadata.email,
  phone: commonMetadata.phone,
  address: commonMetadata.address,
  twitter: commonMetadata.twitter,
  youtube: commonMetadata.youtube,
  linkedin: commonMetadata.linkedin,
  facebook: commonMetadata.facebook,
  instagram: commonMetadata.instagram,
  notes: commonMetadata.notes,
};


// Client
const client = {
  name: commonMetadata.name,
  imageUrl: commonMetadata.imageUrl,
  mainEmail: commonMetadata.mainEmail,
  mainPhone: commonMetadata.mainPhone,
  mainWebsite: commonMetadata.mainWebsite,
  mainAddress: commonMetadata.mainAddress,
  mainInstagram: commonMetadata.mainInstagram,
  type: {
    labelText: globalTexts.type,
    tipText: globalTexts.organizeYourClientsInDifferentGroups,
    elementType: elementType.emoji,
    options: emojiAndDescription.map(emojiAndDescription => ({value: emojiAndDescription.emoji, label: emojiAndDescription.emoji})),
  },
  notes: commonMetadata.notes,
};


// Partner
const partner = {
  name: commonMetadata.name,
  mainPhone: commonMetadata.mainPhone,
  mainEmail: commonMetadata.mainEmail,
  mainWebsite: commonMetadata.mainWebsite,
  mainInstagram: commonMetadata.mainInstagram,
  mainAddress: commonMetadata.mainAddress,
};

const entityMetadata = {
  client,
  partner,
  project,
  clientPerson,
};

export default entityMetadata;