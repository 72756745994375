import ThumbInput from "./ThumbInput";
import ThumbStep from "../Interaction/ThumbStep";
import ThingsFlow from "../Interaction/ThingsFlow";

const ThumbEditProperty = ({propertyName, entity, setEntity, onFinish, finishButtonText, entityMetadata}) => {
  // Parent element controls property name. Null value means no property being edited.
  if(!propertyName) {
    return null;
  };

  const propertyMetadata = entityMetadata ? entityMetadata[propertyName] : undefined;
  const formatterMethod = (propertyMetadata && propertyMetadata.formatterMethod) ? propertyMetadata.formatterMethod : value => value;

  return (
    <ThingsFlow onFinish={onFinish} finishButtonText={finishButtonText}>
      <ThumbStep>
        <ThumbInput
          label={propertyMetadata ? propertyMetadata.labelText : propertyName}
          placeholder={propertyMetadata ? propertyMetadata.placeholderText : null}
          tip={propertyMetadata ? propertyMetadata.tipText : ''}
          value={entity[propertyName]}
          type={propertyMetadata && propertyMetadata.elementType}
          options={propertyMetadata && propertyMetadata.options}
          onChange={({target}) => setEntity({...entity, [propertyName]: formatterMethod(target.value)})}
        />
      </ThumbStep>
    </ThingsFlow>
  );
};

export default ThumbEditProperty;