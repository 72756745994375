import { Link, useNavigate } from 'react-router-dom';
import bgPurpleRoughShimmer2 from '../../assets/img/bgPurpleRoughShimmer2.jpg';
import { icon, languageCode } from '../../data/constants';
import { auth } from '../../integrations/firebase';
import Icon from '../Icon/Icon';

const rotateLanguage = () => {
  const rotation = {
    en: languageCode.es,
    es: languageCode.fr,
    fr: languageCode.en,
  }

  localStorage.setItem('languageCode', rotation[localStorage.getItem('languageCode')]);
  console.log({languageCode: localStorage.getItem('languageCode')});
  window.location.reload();
}

const AppBar = ({backUrl, backText}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className='back-grayscale-4'>
        <div className='flex justify-between padding-xxs padding-left-right-sm'>
          <div className='flex center' onClick={() => navigate(backUrl)}>
            {backUrl ? <Icon name={icon.arrowLeft} dimensions={36} stroke='var(--palette-action-secondary)' /> : <div className='text size-lg uppercase color-action-secondary'>{backText}</div>}
          </div>
          <div className='flex gap-xs'>
            <div className='circle back-grayscale-0'><Icon name={icon.message} dimensions={36} stroke='var(--palette-action-secondary)' onClick={rotateLanguage} /></div>
            <div className='circle back-grayscale-0'><Icon name={icon.user} dimensions={36} stroke='var(--palette-action-secondary)' onClick={() => auth.signOut()} /></div>
          </div>
        </div>

        <div style={{height: '8px', overflow: 'hidden'}}>
          <img src={bgPurpleRoughShimmer2} alt='' />
        </div>

      </div>
      <div className='padding-xxs'></div>
    </>
  );
};

export default AppBar;