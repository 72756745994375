import AppBar from "../AppBar/AppBar";
import dataLayer from "../../data/dataLayer";
import entityMetadata from "../../entities/entityMetadata";
import ThumbEditProperty from "../Input/ThumbEditProperty";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { globalTexts, inter } from "../../utils/internationalization";
import IconAndPropertyEditable from "../Global/IconAndPropertyEditable";

const saveProperty = ({partner, setEditingPropertyName}) => {
  dataLayer.partner.update({partner});
  setEditingPropertyName(null);
};

const PartnerDetailedInfo = () => {
  const {partnerId} = useParams();
  const [partner, setPartner] = useState({});
  const [editingPropertyName, setEditingPropertyName] = useState(null);

  useEffect(() => {
    dataLayer.partner.onGet({partnerId, callback: ({document}) => setPartner(document)});
  }, []);

  return (
    <>
      <AppBar backUrl={`/partner/${partnerId}`} />

      <div className='padding-lg'>
        <div className='text center bold size-xxl'>{partner.name}</div>

        <div className='text center size-lg padding-xxs padding-left-right-lg'>{globalTexts.clickTapToChangeOrAddInfo}</div>
      </div>

      <div className='flex column gap-lg padding-lg'>
        <IconAndPropertyEditable propertyName='name' value={partner.name} onClick={() => setEditingPropertyName('name')} entityMetadata={entityMetadata.partner} />
        <IconAndPropertyEditable propertyName='mainPhone' value={partner.mainPhone} onClick={() => setEditingPropertyName('mainPhone')} entityMetadata={entityMetadata.partner} />
        <IconAndPropertyEditable propertyName='mainEmail' value={partner.mainEmail} onClick={() => setEditingPropertyName('mainEmail')} entityMetadata={entityMetadata.partner} />
        <IconAndPropertyEditable propertyName='mainAddress' value={partner.mainAddress} onClick={() => setEditingPropertyName('mainAddress')} entityMetadata={entityMetadata.partner} />
        <IconAndPropertyEditable propertyName='mainWebsite' value={partner.mainWebsite} onClick={() => setEditingPropertyName('mainWebsite')} entityMetadata={entityMetadata.partner} />
        <IconAndPropertyEditable propertyName='mainInstagram' value={partner.mainInstagram} onClick={() => setEditingPropertyName('mainInstagram')} entityMetadata={entityMetadata.partner} />
      </div>

      <ThumbEditProperty propertyName={editingPropertyName} entity={partner} setEntity={setPartner} onFinish={() => saveProperty({partner, setEditingPropertyName})} finishButtonText={globalTexts.save} entityMetadata={entityMetadata.partner} />
    </>
  );
};

export default PartnerDetailedInfo;
