import { projectStatus } from "../data/constants";

/*
  receive a JSON object like:

  {
    something: {
      en: 'something',
      es: 'algo',
      fr: 'quelque chose',
    },
    somethingElse: {
      en: 'something else',
      es: 'algo más',
      fr: 'autre chose',
    },
  }

  return object with string values on language code set in Local Storage like:
  {
    something: 'something',
    somethingElse: 'something else',
  }
*/
const inter = (texts) => {
  const result = {};

  Object.keys(texts).forEach((key) => {
    result[key] = texts[key][localStorage.getItem('languageCode')];
  });

  return result;
};


// Actions
const globalTextsActions = inter({
  save: {
    en: 'Save',
    es: 'Guardar',
    fr: 'Enregistrer',
  },
  delete: {
    en: 'Delete',
    es: 'Eliminar',
    fr: 'Supprimer',
  },
  confirmDelete: {
    en: 'Confirm delete',
    es: 'Confirmar eliminación',
    fr: 'Confirmer la suppression',
  },
  emptyValueClickToEdit: {
    en: 'Empty. Click to edit',
    es: 'Vacío. Clic para editar',
    fr: 'Vide. Cliquez pour modifier',
  },
  clickTapToChangeOrAddInfo: {
    en: 'Click or tap to change or add info',
    es: 'Click o toque para cambiar o agregar info',
    fr: 'Cliquez ou touchez pour modifier ou ajouter des infos',
  },
});


// Property names
const globalTextsPropertyNames = inter({
  name: {
    en: 'Name',
    es: 'Nombre',
    fr: 'Nom',
  },
  type: {
    en: 'Type',
    es: 'Tipo',
    fr: 'Type',
  },
  email: {
    en: 'Email',
    es: 'Email',
    fr: 'Email',
  },
  phone: {
    en: 'Phone',
    es: 'Teléfono',
    fr: 'Téléphone',
  },
  address: {
    en: 'Address',
    es: 'Dirección',
    fr: 'Adresse',
  },
  mainPhone: {
    en: 'Main phone',
    es: 'Teléfono principal',
    fr: 'Téléphone principal',
  },
  mainEmail: {
    en: 'Main email',
    es: 'Email principal',
    fr: 'Email principal',
  },
  mainInstagram: {
    en: 'Main Instagram for the business',
    es: 'Instagram principal del negocio',
    fr: 'Instagram principal de l\'entreprise',
  },
  mainWebsite: {
    en: 'Main website',
    es: 'Sitio web principal',
    fr: 'Site web principal'
  },
  mainAddress: {
    en: 'Main address',
    es: 'Dirección principal',
    fr: 'Adresse principale',
  },
  instagram: {
    en: 'Instagram',
    es: 'Instagram',
    fr: 'Instagram',
  },
  twitter: {
    en: 'Twitter',
    es: 'Twitter',
    fr: 'Twitter',
  },
  youtube: {
    en: 'Youtube',
    es: 'Youtube',
    fr: 'Youtube',
  },
  linkedin: {
    en: 'Linkedin',
    es: 'Linkedin',
    fr: 'Linkedin',
  },
  facebook: {
    en: 'Facebook',
    es: 'Facebook',
    fr: 'Facebook',
  },
  notes: {
    en: 'Key info to remember',
    es: 'Info clave para recordar',
    fr: 'Infos clés à retenir',
  },
  startDate: {
    en: 'Start date',
    es: 'Fecha de inicio',
    fr: 'Date de début',
  },
  endDate: {
    en: 'End date',
    es: 'Fecha de finalización',
    fr: 'Date de fin',
  },
  description: {
    en: 'Description',
    es: 'Descripción',
    fr: 'Description',
  },
  status: {
    en: 'Status',
    es: 'Estado',
    fr: 'Statut',
  },
  imageUrl: {
    en: 'Image URL',
    es: 'URL de la imagen',
    fr: 'URL de l\'image',
  },
});


// Info
const globalTextsInfo = inter({
  details: {
    en: 'Details',
    es: 'Detalles',
    fr: 'Détails',
  },
  info: {
    en: 'Info',
    es: 'Info',
    fr: 'Info',
  },
  people: {
    en: 'People',
    es: 'Personas',
    fr: 'Personnes',
  },
  projects: {
    en: 'Projects',
    es: 'Proyectos',
    fr: 'Projets',
  },
  whatTypeOfWorkWasDoneDuringThisProject: {
    en: 'What type of work was done during this project',
    es: 'Qué tipo de trabajo se hizo durante este proyecto',
    fr: 'Quel type de travail a été fait pendant ce projet',
  },
  projectNameOrPlaceWhereJobWasDone: {
    en: 'Project name or place where job was done',
    es: 'Nombre del proyecto o lugar donde se hizo el trabajo',
    fr: 'Nom du projet ou lieu où le travail a été fait',
  },
  weWillSendMessagesToThisWhatsapp: {
    en: 'We will send messages to this WhatsApp',
    es: 'Vamos a enviar mensajes a este WhatsApp',
    fr: 'On va envoyer des messages à ce WhatsApp',
  },
  ifTheClientHasNoWhatsappWeWillSendMessagesToThisEmail: {
    en: 'If the client has no WhatsApp, we will send messages to this email',
    es: 'Si el cliente no tiene WhatsApp, vamos a enviar mensajes a este correo',
    fr: 'Si le client n\'a pas de WhatsApp, on va envoyer des messages à cet email',
  },
  organizeYourClientsInDifferentGroups: {
    en: 'Organize your clients in different groups for example according to their industry or the type of work you do for them',
    es: 'Organiza tus clientes en diferentes grupos para, por ejemplo, organizarlos por industria o tipo de trabajo que haces para ellos',
    fr: 'Organisez vos clients en différents groupes, par exemple en fonction de leur industrie ou du type de travail que vous réalisez pour eux',
  },
});


// Constants
const globalTextsConstants = inter({
  [projectStatus.onHold]: {
    en: 'On hold',
    es: 'En espera',
    fr: 'En attente',
  },
  [projectStatus.cancelled]: {
    en: 'Cancelled',
    es: 'Cancelado',
    fr: 'Annulé',
  },
  [projectStatus.finished]: {
    en: 'Finished',
    es: 'Finalizado',
    fr: 'Terminé',
  },
  [projectStatus.exploring]: {
    en: 'Exploring',
    es: 'Explorando',
    fr: 'Explorant',
  },
  [projectStatus.inProgress]: {
    en: 'In progress',
    es: 'En progreso',
    fr: 'En cours',
  },
  [projectStatus.negotiating]: {
    en: 'Negotiating',
    es: 'Negociando',
    fr: 'Négociant',
  },
  [projectStatus.preparingToStart]: {
    en: 'Preparing to start',
    es: 'Preparando para iniciar',
    fr: 'En préparation pour commencer',
  },
});

const globalTexts = {
  ...globalTextsInfo,
  ...globalTextsActions,
  ...globalTextsConstants,
  ...globalTextsPropertyNames,
};

export { inter, globalTexts };